// view-source:https://www.edfpulseandyou.fr/community
// https://www.edfpulseandyou.fr/commonGlobal/widgets/widgets/communityMap/communityMap.js

// import MarkerImage from '../../assets/images/marker-icon.png'
// import MarkerShadowImage from '../../assets/images/marker-shadow.png'
import MarkerImage from '../../assets/images/marker-icon-2x.png'
import MarkerShadowImage from '../../assets/images/marker-shadow-2x.png'

const tiles = L.tileLayer('https://a.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png', {
  attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, Imagery © <a href="https://carto.com/attribution">Carto</a>',
  maxZoom: 16
});

const container = document.querySelector('#sidebar-map');

const map = L.map('sidebar-map', {
  center: L.latLng(46.855, 2.45), // France
  zoom: container.getAttribute('data-zoom') || 5,
  layers: [tiles]
});

// MarkerImage aspect ratio 25:41 (or 50:82 for 2x.png)
const markerImageSize = { width: 25, height: 41 }
const markerZoom = 1.2 // if > 1, use *-2x.png images to avoid serving images with low resolution
const iconSize = { width: markerImageSize.width*markerZoom, height: markerImageSize.height*markerZoom }

// MarkerShadowImage aspect rat 41:41
const shadowSize = { width: iconSize.height, height: iconSize.height }

const markerIcon = L.icon({
  iconUrl: MarkerImage,
  shadowUrl: MarkerShadowImage,
  iconSize:     [iconSize.width, iconSize.height],        // [25, 41]   : keep MarkerImage aspect ratio
  iconAnchor:   [iconSize.width/2, iconSize.height],      // [12.5, 41] : horizontal centering
  popupAnchor:  [0, -(iconSize.height+5)],                // [0, -46)]  : 5px margin on top of marker
  shadowSize:   [shadowSize.width, shadowSize.height],    // [41, 41]   : keep MarkerShadowImage aspect ratio
  shadowAnchor: [iconSize.width/2+2, shadowSize.height],  // [14.5, 41] : 2px horizontal position adjustment
});

const markers = L.markerClusterGroup({
  chunkedLoading: true,
  singleMarkerMode: false, // false,
  spiderfyOnMaxZoom: true,
  maxClusterRadius: 60, // was 80 by default
  // iconCreateFunction: function(cluster) {
  //   return L.divIcon({
  //     html: "<div><span>"+cluster.getChildCount()+"</span></div>",
  //     className: 'marker-cluster sidebar-map-marker-cluster',
  //     iconSize: null
  //   });
  // }
});

// Expected to be generated in location.js
//
// EdfPAY.locations = [
//   {
//     "label":"Sample projet",
//     "url":"https://partner.example/project-path",
//     "lng":-2.932644,
//     "lat":48.202047
//   },
// ]
const locations = (typeof EdfPAY === 'undefined') ? [] : EdfPAY.locations;

locations.forEach(location => {
  if (location && location.lng && location.lat) {
    const title = `<a target='_blank' href='${location.url}' class='leaflet-marker-label'>${location.label}</a>`;
    const latLng = L.latLng(location.lat, location.lng)
    const marker = L.marker(latLng, {
      title: location.label,
      icon: markerIcon,
    });
    marker.bindPopup(title + `<div class='leaflet-marker-description'>${location.description}</div>`);
    markers.addLayer(marker);
  }
});


map.addLayer(markers);
